import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="unearthed-arcana"></a>Unearthed Arcana</h2>
    <h3><a id="defence-bonus"></a>DEFENCE BONUS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
In the standard d20 rules, a character�s skill at attacking gets better as
he goes up in level � but not so his skill at avoiding attacks. Characters
rely on armor and an ever-growing collection of magic items to protect
them in combat. But what about campaigns in which it�s not common or
appropriate for characters to go everywhere in full plate?
    <p>
This variant system is particularly appropriate for swashbuckling or
stealth-based campaigns, for settings in which firearms are common, for
seafaring campaigns (in which the characters would rather not wear armor
for fear of <a style={{
        "color": "#579eb6"
      }} href="wildernessAndEnvironment.html#drowning">drowning</a>), or any
other setting in which armor is not worn on a day-to-day basis�even by
adventurers.
    </p>
    <b><a id="table-defence-bonus"></a>Table: Defence Bonus</b>
    <table cellSpacing="0">
      <thead>
        <tr>
          <th>
Level
          </th>
          <th>
A<sup>1</sup>
          </th>
          <th>
B<sup>2</sup>
          </th>
          <th>
C<sup>3</sup>
          </th>
          <th>
D<sup>4</sup>
          </th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td colSpan="5">
            <ol>
              <li>Use column A for monk, sorcerer, or wizard.
              </li>
              <li>Use column B for bard, ranger or rogue.
              </li>
              <li>Use column C for barbarian or druid.
              </li>
              <li>Use column D for cleric, fighter or paladin.
              </li>
            </ol>
          </td>
        </tr>
      </tfoot>
      <tbody>
        <tr className="odd-row">
          <td align="center">
1st
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+4
          </td>
          <td align="center">
+6
          </td>
        </tr>
        <tr>
          <td align="center">
2nd
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+4
          </td>
          <td align="center">
+6
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
3rd
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+4
          </td>
          <td align="center">
+5
          </td>
          <td align="center">
+7
          </td>
        </tr>
        <tr>
          <td align="center">
4th
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+4
          </td>
          <td align="center">
+5
          </td>
          <td align="center">
+7
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
5th
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+4
          </td>
          <td align="center">
+5
          </td>
          <td align="center">
+7
          </td>
        </tr>
        <tr>
          <td align="center">
6th
          </td>
          <td align="center">
+4
          </td>
          <td align="center">
+5
          </td>
          <td align="center">
+6
          </td>
          <td align="center">
+8
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
7th
          </td>
          <td align="center">
+4
          </td>
          <td align="center">
+5
          </td>
          <td align="center">
+6
          </td>
          <td align="center">
+8
          </td>
        </tr>
        <tr>
          <td align="center">
8th
          </td>
          <td align="center">
+4
          </td>
          <td align="center">
+5
          </td>
          <td align="center">
+6
          </td>
          <td align="center">
+8
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
9th
          </td>
          <td align="center">
+5
          </td>
          <td align="center">
+6
          </td>
          <td align="center">
+7
          </td>
          <td align="center">
+9
          </td>
        </tr>
        <tr>
          <td align="center">
10th
          </td>
          <td align="center">
+5
          </td>
          <td align="center">
+6
          </td>
          <td align="center">
+7
          </td>
          <td align="center">
+9
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
11th
          </td>
          <td align="center">
+5
          </td>
          <td align="center">
+6
          </td>
          <td align="center">
+7
          </td>
          <td align="center">
+9
          </td>
        </tr>
        <tr>
          <td align="center">
12th
          </td>
          <td align="center">
+6
          </td>
          <td align="center">
+7
          </td>
          <td align="center">
+8
          </td>
          <td align="center">
+10
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
13th
          </td>
          <td align="center">
+6
          </td>
          <td align="center">
+7
          </td>
          <td align="center">
+8
          </td>
          <td align="center">
+10
          </td>
        </tr>
        <tr>
          <td align="center">
14th
          </td>
          <td align="center">
+6
          </td>
          <td align="center">
+7
          </td>
          <td align="center">
+8
          </td>
          <td align="center">
+10
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
15th
          </td>
          <td align="center">
+7
          </td>
          <td align="center">
+8
          </td>
          <td align="center">
+9
          </td>
          <td align="center">
+11
          </td>
        </tr>
        <tr>
          <td align="center">
16th
          </td>
          <td align="center">
+7
          </td>
          <td align="center">
+8
          </td>
          <td align="center">
+9
          </td>
          <td align="center">
+11
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
17th
          </td>
          <td align="center">
+7
          </td>
          <td align="center">
+8
          </td>
          <td align="center">
+9
          </td>
          <td align="center">
+11
          </td>
        </tr>
        <tr>
          <td align="center">
18th
          </td>
          <td align="center">
+8
          </td>
          <td align="center">
+9
          </td>
          <td align="center">
+10
          </td>
          <td align="center">
+12
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
19th
          </td>
          <td align="center">
+8
          </td>
          <td align="center">
+9
          </td>
          <td align="center">
+10
          </td>
          <td align="center">
+12
          </td>
        </tr>
        <tr>
          <td align="center">
20th
          </td>
          <td align="center">
+8
          </td>
          <td align="center">
+9
          </td>
          <td align="center">
+10
          </td>
          <td align="center">
+12
          </td>
        </tr>
      </tbody>
    </table>
    <h5><a id="behind-the-curtain-class-defence"></a>
BEHIND THE CURTAIN: CLASS DEFENCE
    </h5>
    <div style={{
      "marginLeft": "40px"
    }}>Using the defense bonus variant in your game means that sometimes, at
least, characters won�t want to wear armor � their defense bonus provides
them with free protection that�s just as good as armor. Funds that would
otherwise be spent improving a character�s armor can instead be spent on
other gear, which means the characters� power level will increase
slightly.
      <p>
Characters may still desire the properties of a specific kind of magic
armor or of armor special abilities. Be prepared to create new magic
items, such as cloaks, robes, vests, or vestments, to support those
desires.
      </p>
      <p>
Clearly, the class defense system is best for characters who must choose
between going unarmored and risking arcane spell failure � sorcerers and
wizards in particular. Classes limited to light or medium armor also
flourish under this system, since they can multiclass to gain the benefit
of a higher defense bonus.
      </p>
      <p>
Spells that affect metal are less useful under this system, since metal
armor is less common.
      </p>
      <p>
Touch attacks
are less effective under this system, since most characters� touch ACs are
significantly higher than in a standard game.
      </p></div>
    <h5><a id="the-class-defence-bonus"></a>
THE CLASS DEFENCE BONUS
    </h5>
In this variant, every character has a defense bonus based on his
character level. The defense bonus applies to Armor Class. However, it
does not stack with the character�s armor bonus. A character wearing armor
gains his armor bonus (including any enhancement to that bonus) or his
defense bonus � whichever is higher � but not both. The defense bonus stacks
with all other bonuses to AC, including the character�s shield bonus,
natural armor bonus, and so
forth.
    <p>
Unlike an armor bonus, a
defense bonus does improve a character�s AC against touch attacks.
    </p>
    <p>
A character�s defense bonus is derived from his character level and class,
as shown on <a style={{
        "color": "#579eb6"
      }} href="#table-defence-bonus">Table: Defense Bonus</a>. For a
multiclass character, use the highest defense bonus of those offered by
the character�s classes. For example, a 2nd-level barbarian has a defense
bonus of +4. If the character gains a level of cleric (becoming a
2nd-level barbarian/1st-level cleric), her defense bonus increases to +7,
because the cleric�s +7 at 3rd character level is better than the
barbarian�s +5 at 3rd character level.
    </p>
    <h5><a id="defence-bonuses-for-other-classes"></a>
DEFENCE BONUSES FOR OTHER CLASSES
    </h5>
For classes not mentioned here, determine a character�s class defense
bonus based on the armor
proficiency granted by the class (and only that gained from the
class � you can�t take an Armor Proficiency feat to improve your defense
bonus). Consult <a style={{
      "color": "#579eb6"
    }} href="#defense-bonuses-for-other-classes">Table: Defense
Bonus For Other Classes</a>; the defense bonus progression refers to the
indicated column on <a style={{
      "color": "#579eb6"
    }} href="#table-defense-bonus">Table: Defense Bonus</a>.
    <b><a id="table-defence-bonus-for-other-classes"></a>Table: Defence Bonus For Other Classes</b>
    <table cellSpacing="0" className="right">
      <tr>
        <th align="left">
Armor Proficiency
        </th>
        <th align="left">
Defense Bonus Progression
        </th>
      </tr>
      <tr className="odd-row">
        <td>
None
        </td>
        <td>
Column A
        </td>
      </tr>
      <tr>
        <td>
Light
        </td>
        <td>
Column B
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Light and medium
        </td>
        <td>
Column C
        </td>
      </tr>
      <tr>
        <td>
Light, medium, and heavy
        </td>
        <td>
Column D
        </td>
      </tr>
    </table>
    <h5><a id="monster-defence-bonus"></a>
MONSTER DEFENCE BONUS
    </h5>
Monsters do not have inherent defense bonuses unless they also have levels
in a class or are normally proficient
with armor. When calculating a monster�s defense bonus to AC, do not
include the monster�s base HD or level adjustment (if any).
    <p>
For example, a typical green dragon, gargoyle or black pudding has no
class levels and is not proficient with any armor. Such creatures do not get a defense bonus.
    </p>
    <p>
If a creature is proficient
with one or more types of armor, however, it gains a defense bonus. See
      <a style={{
        "color": "#579eb6"
      }} href="#table-creature-defense-bonuses">Table: Creature Defense
Bonuses</a>.
    </p>
    <p>
If a creature has levels in a class, it gains a defense bonus just like
any other character with a class. This bonus does not stack with any
defense bonus the creature may have from armor proficiency.
    </p>
    <p>
For instance, giants are considered proficient
with whatever type of armor (light, medium or heavy) they are described as
wearing. <a style={{
        "color": "#579eb6"
      }} href="monstersG.html#hill-giant">Hill giants</a>, described as wearing hide armor, therefore have a +2
defense bonus (hide armor is medium armor). Since this is lower than the
+3 bonus of hide armor, the typical hill giant probably prefers to wear
his armor. A 1st-level hill giant barbarian, however, would have a defense
bonus of +4, and would benefit from discarding his hide armor (though he�d
gain a higher armor bonus by
putting on chainmail or a breastplate).
    </p>
    <b><a id="table-creature-defence-bonuses"></a>Table: Creature Defence Bonuses</b>
    <table cellSpacing="0">
      <tr>
        <th align="left">
Armor Proficiency
        </th>
        <th align="left">
Defense Bonus
        </th>
      </tr>
      <tr className="odd-row">
        <td>
None
        </td>
        <td>
+0
        </td>
      </tr>
      <tr>
        <td>
Light
        </td>
        <td>
+1
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Light and medium
        </td>
        <td>
+2
        </td>
      </tr>
      <tr>
        <td>
Light, medium and heavy
        </td>
        <td>
+4
        </td>
      </tr>
    </table>
    <h3><a id="armor-as-damage-reduction"></a>ARMOR AS DAMAGE REDUCTION</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
In the abstract d20 combat system, a character�s armor defends him by
reducing the chance that an attack will deal damage. That system
simplifies the realities of battle in order to streamline combat
resolution. An attack that fails due to a character�s armor or natural
armor doesn�t really fail to connect, but rather fails to connect with
enough force to deal any damage. (That�s why touch attacks ignore a
character�s armor and natural armor � the touch attack only needs to connect
to deliver its effect, and need not actually breach the target�s armor.)
    <p>
If you�re willing to add a layer of complexity to your combats, consider
this variant. In this system, armor reduces the amount of damage dealt by
an attack instead of merely turning would-be hits into misses. Armor still
prevents some hits outright, but also reduces the deadliness of attacks
that do connect. In essence, the system "gives up" some of armor�s ability
to turn hits into misses in exchange for a small reduction in damage dealt
by any given attack.
    </p>
    <h5><a id="armor-damage-reduction-values"></a>
ARMOR DAMAGE REDUCTION VALUES
    </h5>
In this system, armor offers two benefits against attacks: a minor bonus
to AC, which functions just like the armor bonus in the standard d20 rules
but is usually lower in value; and <a style={{
      "color": "#579eb6"
    }} href="abilitiesAndConditions.html#damage-reduction">damage reduction</a>. See
    <a style={{
      "color": "#579eb6"
    }} href="#table-armor-and-damage-reduction">Table: Armor and Damage
Reduction</a> for the armor bonus and DR values for common armor types.
(All other armor statistics, such as maximum Dexterity bonus, armor check
penalty, and arcane spell failure chance, are unchanged.)
    <p>
For armors not covered on <a style={{
        "color": "#579eb6"
      }} href="#table-armor-and-damage-reduction">Table:
Armor and Damage Reduction</a>, you can determine the new armor values and
damage reduction based on the standard armor bonus. To determine the armor�s
damage reduction, divide the armor�s normal armor bonus by 2 (rounding
down). To determine the armor�s new armor bonus, subtract the DR from the
normal armor bonus. For example, studded leather has a normal armor bonus
of +3. That gives it a DR of 1/- (half of 3, rounded down) and a new armor
bonus of +2 (3 minus 1).
    </p>
    <b><a id="table-armor-and-damage-reduction"></a>Table: Armor and Damage Reduction</b>
    <table cellSpacing="0" style={{
      "width": "50%"
    }}>
      <thead>
        <tr>
          <th align="left">
Armor
          </th>
          <th>
Armor
Bonus<sup><sub>1</sub></sup>
          </th>
          <th>
Damage
Reduction
          </th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td colSpan="3">
            <ol>
              <li>Add any enhancement bonus to
this value.
              </li>
            </ol>
          </td>
        </tr>
      </tfoot>
      <tbody>
        <tr className="h2">
          <th colSpan="3">
Light armor
          </th>
        </tr>
        <tr className="odd-row">
          <td className="sub">
Padded
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
none
          </td>
        </tr>
        <tr>
          <td className="sub">
Leather
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
1/-
          </td>
        </tr>
        <tr className="odd-row">
          <td className="sub">
Studded leather
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
1/-
          </td>
        </tr>
        <tr>
          <td className="sub">
Chain shirt
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
2/-
          </td>
        </tr>
        <tr className="h2">
          <th colSpan="3">
Medium armor
          </th>
        </tr>
        <tr>
          <td className="sub">
Hide
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
1/-
          </td>
        </tr>
        <tr className="odd-row">
          <td className="sub">
Scale mail
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
2/-
          </td>
        </tr>
        <tr>
          <td className="sub">
Chainmail
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
2/-
          </td>
        </tr>
        <tr className="odd-row">
          <td className="sub">
Breastplate
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
2/-
          </td>
        </tr>
        <tr className="h2">
          <th colSpan="3">
Heavy armor
          </th>
        </tr>
        <tr className="odd-row">
          <td className="sub">
Splint mail
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
3/-
          </td>
        </tr>
        <tr>
          <td className="sub">
Banded mail
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
3/-
          </td>
        </tr>
        <tr className="odd-row">
          <td className="sub">
Half-plate
          </td>
          <td align="center">
+4
          </td>
          <td align="center">
3/-
          </td>
        </tr>
        <tr>
          <td className="sub">
Full plate
          </td>
          <td align="center">
+4
          </td>
          <td align="center">
4/-
          </td>
        </tr>
      </tbody>
    </table>
    <h5><a id="magic-armor"></a>
MAGIC ARMOR
    </h5>
An armor�s enhancement
bonus (if any) increases its armor bonus to AC, but has no effect
on the armor�s damage reduction. A +3 chain shirt, for example, adds +5 to
AC and grants damage reduction 2/-.
    <h5><a id="stacking-damage-reduction"></a>
STACKING DAMAGE REDUCTION
    </h5>
The <a style={{
      "color": "#579eb6"
    }} href="abilitiesAndConditions.htm#damage-reduction">damage
reduction</a> granted by armor stacks with other damage reduction of the
same type (that is, damage reduction that has a dash after the number). A
7th-level barbarian wearing a breastplate has DR 3/- (1/- from his class
levels and 2/- from his armor). A fighter wearing full plate armor who is
the target of a <a style={{
      "color": "#579eb6"
    }} href="spellsS.html#stoneskin">stoneskin</a> spell, however, has DR 4/- from the armor and
10/adamantine from the
spell.
    <h5><a id="shields"></a>
SHIELDS
    </h5>
Shields function normally in this variant, granting their full shield bonus to AC. Unlike with
armor, a shield�s effectiveness is measured wholly by its ability to keep
an attack from connecting with your body.
    <h5><a id="natural-armor"></a>
NATURAL ARMOR
    </h5>
A creature�s natural armor also provides a modicum of <a style={{
      "color": "#579eb6"
    }} href="abilitiesAndConditions.html#damage-reduction">damage reduction</a>. Divide
the monster�s natural armor
bonus (not including any enhancement bonus) by 5 to
determine the monster�s damage reduction. The same value is subtracted
from the monster�s natural armor bonus to find the monster�s new AC. These
calculations are summarized in <a style={{
      "color": "#579eb6"
    }} href="#table-natural-armor-and-damage-reduction">Table: Natural Armor and Damage
Reduction</a>.
    <p>
If the creature already has damage reduction, either add the value gained
from natural armor (if the existing damage reduction is of the same type)
or treat it as a separate DR value (if it is of a different type).
    </p>
    <p>
For example, a <a style={{
        "color": "#579eb6"
      }} href="monstersMtoN.html#mummy">mummy</a> normally has a natural armor bonus of +10.
This gives it DR 2/-, and its natural armor bonus is reduced by 2 points
to +8 (making it�s AC 18). Since the mummy already has DR 5/- as a special
quality, its total damage reduction becomes DR 7/-.
    </p>
    <p>
A mature adult <a style={{
        "color": "#579eb6"
      }} href="monstersDrtoDw.html#red-dragon">red dragon</a> has a natural armor bonus of +24.
This gives it DR 4/-, and its natural armor bonus is reduced by 4 points
to +20 (making its AC 28). The dragon�s existing damage reduction is
10/magic, so the two damage reduction values remain separate.
    </p>
    <p>
Finally, a <a style={{
        "color": "#579eb6"
      }} href="monstersG.html#frost-giant">frost giant</a> has a +9 natural armor bonus, so it
gains DR 1/- from natural armor. The chain shirt it wears gives it an
additional DR 2/-. If the frost giant were a 7th-level barbarian, the
barbarian class levels would give it DR 1/-. These three values add up to
DR 4/-. The giant�s AC would be 20 (10, +8 natural armor bonus, +2 chain
shirt).
    </p>
    <b><a id="table-natural-armor-and-damage-reduction"></a>Table: Natural Armor and Damage Reduction</b>
    <table cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tr>
        <th>
Natural
Armor Bonus
        </th>
        <th>
Damage
Reduction
        </th>
        <th>
Subtract from
Natural Armor
        </th>
      </tr>
      <tr className="odd-row">
        <td align="center">
0-4
        </td>
        <td align="center">
none
        </td>
        <td align="center">
0
        </td>
      </tr>
      <tr>
        <td align="center">
5-9
        </td>
        <td align="center">
1/-
        </td>
        <td align="center">
1
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
10-14
        </td>
        <td align="center">
2/-
        </td>
        <td align="center">
2
        </td>
      </tr>
      <tr>
        <td align="center">
15-19
        </td>
        <td align="center">
3/-
        </td>
        <td align="center">
3
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
20-24
        </td>
        <td align="center">
4/-
        </td>
        <td align="center">
4
        </td>
      </tr>
      <tr>
        <td align="center">
25-29
        </td>
        <td align="center">
5/-
        </td>
        <td align="center">
5
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
30-34
        </td>
        <td align="center">
6/-
        </td>
        <td align="center">
6
        </td>
      </tr>
      <tr>
        <td align="center">
35-39
        </td>
        <td align="center">
7/-
        </td>
        <td align="center">
7
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
40-44
        </td>
        <td align="center">
8/-
        </td>
        <td align="center">
8
        </td>
      </tr>
    </table>
    <h5><a id="behind-the-curtain-armor-as-dr"></a>
BEHIND THE CURTAIN: ARMOR AS DR
    </h5>
    <div style={{
      "marginLeft": "40px"
    }}>It�s pretty easy to see the effect of this variant system: attacks hit
more often, but do less damage. What does that really mean?
      <p>
Low-level combat tends to be less dangerous for armored characters.
Although their ACs are lower (and thus their chance of being damaged is
higher), this is more than offset by the reduced damage suffered by
attacks. A typical goblin warrior, for instance, can barely hurt a
character wearing splint mail, because the armor�s damage reduction
entirely negates the damage dealt by an average hit. Even though the
goblin will hit more often, it will likely end up dealing less total
damage over the course of a typical battle.
      </p>
      <p>
A mid-level fighter in full plate armor must still be cautious when
fighting an ogre, but his armor reduces the ogre�s average damage by 25%
while only increasing its chance to hit by 20% � a net gain for the fighter.
      </p>
      <p>
At higher levels, however, the balance shifts back in favor of monsters
that deal large amounts of damage per hit. When facing a Huge earth
elemental, a fighter in full plate will be hit 20% more often (due to the
4-point reduction in AC), but his 4 points of damage reduction now only
reduces his opponent�s average damage by less than 17%. Advantage:
elemental. Thus, high-level characters must be more careful when battling
monsters with extreme damage-dealing capability.
      </p></div>
    <h5><a id="combo-defense-bonus-and-damage-reduction"></a>
COMBO: DEFENCE BONUS AND DAMAGE REDUCTION
    </h5>
    <div style={{
      "marginLeft": "40px"
    }}>You can combine the <a style={{
        "color": "#579eb6"
      }} href="#defence-bonus">defense bonus</a> variant and
the armor as damage reduction variant in a variety of ways to create a
more complex system.
      <p>
Using both systems as written, many characters will wear armor even if the
armor bonus provided is lower
than the defense bonus gained from class level. Because the character gets
the higher of his defense bonus or armor bonus, the character can wear
armor and benefit from its damage reduction while relying on his defense
bonus for a higher Armor Class.
      </p>
      <p>
If that�s not to your liking, you can rule that a character�s armor bonus overrides his defense
bonus, even if the defense bonus is higher. This forces characters to make
a tough choice between having a high AC and having damage reduction.
      </p></div>
    <h3><a id="damage-conversion"></a>DAMAGE CONVERSION</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
In the standard d20 rules, armor reduces the chance of a character taking
damage in combat�but that�s the limit of its protection. In this variant,
armor not only protects in that manner, but also turns fatal blows into
less-threatening hits. Armored characters are often more easily battered
into unconsciousness than brought down by lethal damage. It�s a system
that works well in campaigns with a great deal of combat between armored
foes, but in which magical healing is not common. It�s also ideal in a
world where ethical or legal standards dictate that subduing or capturing
opponents is preferable to killing them.
    <h5><a id="armor-damage-conversion"></a>
ARMOR DAMAGE CONVERSION
    </h5>
Armor, in addition to adding a bonus to AC, also converts lethal damage
from physical attacks into nonlethal damage. (Shields provide a shield bonus to AC, as normal, but do
not convert damage.)
    <p>
Each time an armor-wearing character is struck by an attack that deals
lethal damage, the amount of damage dealt to the character is reduced by
an amount equal to the armor
bonus (including enhancement) of the armor worn. The character takes
and equal amount of nonlethal damage. Damage that is not affected by
      <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#damage-reduction">damage reduction</a> (energy damage and the like) is not converted.
    </p>
    <p>
For example, while wearing +1 full plate (total armor bonus +9), Kroh is struck by an
arrow for 6 points of damage. Since the armor can convert up to 9 points
of damage per attack, the entire 6 points is converted from lethal damage
to nonlethal damage. Kroh�s hit point total remains the same, but he
increases his nonlethal damage total by 6 points. Later, a hill giant
strikes Kroh for a whopping 22 points of damage. The armor converts 9
points of this damage to nonlethal damage, but the remaining 13 points are
deducted from Kroh�s hit points.
    </p>
    <p><b><a id="nonlethal-damage"></a>Nonlethal Damage:</b>
An armor-wearing character can ignore nonlethal damage equal to his
armor bonus. (In effect, armor
grants <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#damage-reduction">damage reduction</a> equal to its armor bonus against nonlethal
attacks.)
    </p>
    <p><b><a id="natural-armor-and-damage-conversion"></a>Natural Armor:</b>
At your option, you can make natural armor work in the same manner.
However, this means that almost no defeated monster is truly dead, which
may prove problematic (see <a style={{
        "color": "#579eb6"
      }} href="#behind-the-curtain-damage-conversion">Behind The Curtain: Damage
Conversion</a>). This rule also interacts strangely with
      <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#regeneration">regeneration</a> � since all damage dealt to a creature with regeneration is
treated as nonlethal damage, a regenerating creature with armor or natural
armor actually takes less damage than normal when using this system. In
case of regenerating creatures, consider eliminating the rule that natural
armor works in the same manner.
    </p>
    <h5><a id="behind-the-curtain-damage-conversion"></a>
BEHIND THE CURTAIN: DAMAGE CONVERSION
    </h5>
    <div style={{
      "marginLeft": "40px"
    }}>With this system, a character still takes as much damage from a hit as in
the normal combat rules. Barring any magical healing delivered during the
fight, a battle lasts just as long as in a standard game. Since nonlethal
damage goes away faster than lethal damage, though, characters can
recuperate from their battles relatively quickly, even without magical
healing, making this variant ideal for low-magic campaigns.
      <p>
However, healing spells become potentially doubly effective, since they
heal an equal amount of lethal and nonlethal damage.
      </p>
      <p>
Nonlethal attacks become less frightening to armored opponents. A fighter
in full plate simply has no fear of unarmed opponents, since they have
little chance of injuring him. (Of course, they can still <a style={{
          "color": "#579eb6"
        }} href="specialAttacks.html#overrun">overrun</a>, <a style={{
          "color": "#579eb6"
        }} href="specialAttacks.html#trip">trip</a>,
or <a style={{
          "color": "#579eb6"
        }} href="specialAttacks.html#grapple">grapple</a> him, so he�d be wise to keep and eye on them all the same.)
      </p>
      <p>
Another effect is that defeated foes remain alive (and unconscious) unless
dispatched after the fight. This can decrease character mortality
dramatically � since most characters who fall in battle will be merely
unconscious, but not dying � but it also introduces the potentially ugly
postcombat scene of the characters feeling it necessary to slit the
throats of their unconscious foes. Some characters, particulary paladins
or other chivalrous types, may suffer serious moral qualms.
      </p>
      <p>
This variant is probably best for campaigns in which it�s acceptable for
the bad guys to survive a fight. Perhaps a defeated villain�s honor
prevents him from returning to plague the heroes at a later date, or maybe
the style of your setting rewards characters for defeating opponents
without killing them outright (such as in a swashbuckling campaign).
Otherwise, characters may feel that they are punished for refusing to
murder unconscious foes with regularity, since those enemies will
certainly recover from their injuries and vow vengeance against the PCs.
Encounters in the campaign may often involve fighting the same opponents
again and again, rather than fighting new monsters and opponents.
      </p></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      